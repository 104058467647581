import React from "react"
import traiteurHero from '../images/traiteurHero.jpg';
import Layout from "../components/layout"
import Product from "../components/product/product"
import SEO from "../components/seo"
import bli from "../images/products/caterer/blinis.jpg";
import mau from "../images/products/caterer/mauricettes.jpg";
import min from "../images/products/caterer/miniardises.jpg";
import nav from "../images/products/caterer/navetteBrioche.jpg";
import pai from "../images/products/caterer/painSurprise.jpg";
import tart from "../images/products/caterer/tartines.jpg";
import toa from "../images/products/caterer/toasts.jpg";
import wra from "../images/products/caterer/wraps.jpg";

const products =[
  {
    name:"Blinis au saumon",
    description : "Saumon fumé, chantilly au raifort et blinis.",
    img : bli
  },
  {
    name:"Malicettes",
    description : "Assortiments de malicettes garnies.",
    img : mau
  },
  {
    name:"Navettes briochées",
    description : "Petites brioches garnies de mousse de canard et de fromage à tartiner.",
    img : nav
  },
  {
    name:"Pain surprise",
    description : "Possibilité en 6 ou 12 personnes avec ou sans noix garnis de différentes variétés de charcuterie, fromage et saumon fumé.",
    img : pai
  },
  {
    name:"Tartines",
    description : "Tranches de pain grillées garnies de douceurs salées.",
    img : tart
  },
  {
    name:"Toasts",
    description : "Pain de mie garni de différentes variétés de charcuterie, fromage et saumon fumé.",
    img : toa
  },
  {
    name:"Wraps",
    description : "Tortilla garnie de jambon, fromage et salade verte ou de saumon fumé et de salade verte.",
    img : wra
  },
  {
    name:"Mignardises",
    description : "Assortiment de petites gourmandises sucrées.",
    img : min
  },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Traiteur" />
    <div className="products">
      <section>
        <div className="fluid-container">
          <div className="row">
            <img src={traiteurHero} alt="Traiteur" style={{width:"100%"}}/>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <h1 className="col-md-12 times" style={{textAlign:"center",margin:"62px 0px"}}>Traiteur</h1>
          </div>
          <div className="row">
            <div className="col-md-12" style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}>
            {
              products.map((product)=><Product name={product.name} description={product.description} img={product.img} />)
            }
            </div>
          </div>
        </div>
      </section>

    </div>
  </Layout>
)

export default IndexPage
